import styled from 'styled-components';

import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Content = styled.div`
    color: ${Colors.violet};
    width: 100%;

    @media ${MediaQueries.phone} {
        padding: 12rem 2rem 8rem 2rem;
        text-align: center;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        padding-top: 9rem;
        text-align: center;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
        padding-top: 12rem;
    }
`;

export const Title = styled.h3`
    text-align: center;
`;

export const Description = styled.p`
    padding: 4rem 0rem;

    @media ${MediaQueries.desktop} {
        text-align: center;
    }
`;

export const FeaturesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media ${MediaQueries.desktop} {
        margin-top: 4rem;
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        flex-wrap: wrap;
        padding: 4rem 10rem 8rem 10rem;
        justify-content: space-around;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        margin-top: 4rem;
    }
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
        width: ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING}rem;
    }

    @media ${MediaQueries.desktop} {
        width: ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING}rem;
        padding: 0 1rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const Label = styled.h4`
    color: ${Colors.violet};
    padding-top: 4rem;

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE / 4}rem;
    }
    @media ${MediaQueries.phone} {
        padding-top: 2rem;
    }
`;
