import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
    WorkWithUsHero,
    DigitalTransformation,
    EndToEndServices,
    OurProcess,
    Award,
    BuildYourProduct
} from 'components/solutii-automotive';

import seo from 'data/seo.json';
import { SEO } from 'components/ui';
import { PAGE_LOADED_WHY_WORK_WITH_US, PAGE_UNLOADED_WHY_WORK_WITH_US } from 'consts';

// Since this page is in romanian we don't need capitalization transform.
const UndoTextTransform = styled.div`
    h1,
    h2,
    h3 {
        text-transform: none;
    }
`;

export default function WorkWithUs() {
    useEffect(() => {
        document.dispatchEvent(new CustomEvent(PAGE_LOADED_WHY_WORK_WITH_US));
        return () => {
            document.dispatchEvent(new CustomEvent(PAGE_UNLOADED_WHY_WORK_WITH_US));
        };
    }, []);

    return (
        <UndoTextTransform>
            <SEO
                title={seo.automotiveSolutionsTitle}
                description={seo.automotiveSolutionsDescription}
                ogTitle={seo.automotiveSolutionsTitle}
                ogDescription={seo.automotiveSolutionsDescription}
                ogImage={seo.automotiveOgImage}
            />
            <WorkWithUsHero />
            <DigitalTransformation />
            <EndToEndServices />
            <OurProcess />
            <Award />
            <BuildYourProduct />
        </UndoTextTransform>
    );
}
