import styled from 'styled-components';
import { Link } from 'gatsby';

import { TABLET_GRID_SIZE, DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';
import { Colors, MediaQueries } from 'environment';
import { BackgroundImage } from 'components/ui';

export const Container = styled.section`
    display: flex;
    justify-content: center;
`;

export const ImageLink = styled(Link)`
    margin-top: -13rem;
    position: absolute;
    left: 0;
    z-index: 1;

    @media ${MediaQueries.tablet} {
        margin-top: -9rem;
    }

    @media ${MediaQueries.phone} {
        margin-top: -9rem;
        margin-left: 2rem;
    }

    img {
        height: 4rem;
    }
`;

export const Content = styled.div`
    color: ${Colors.violet};
    width: 100%;
    position: relative;
    padding-top: 12rem;
    padding-bottom: 20rem;

    @media ${MediaQueries.phone} {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        text-align: left;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 12 + DESKTOP_GRID_SPACING * 11}rem;
        padding-top: 16rem;
        padding-bottom: 13vw;
    }
`;

export const Title = styled.h1`
    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        text-align: center;
        line-height: 5rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 8}rem;
    }
`;

export const Description = styled.p`
    padding-top: 4rem;
    text-align: left;
    font-weight: normal;

    @media ${MediaQueries.phone} {
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    margin-top: 8rem;

    @media ${MediaQueries.phone} {
        display: flex;
        justify-content: center;
    }
`;

export const WorkBackgroundImage = styled(BackgroundImage)`
    div {
        height: 100%;
    }
`;
