import React, { useState } from 'react';

import { BaseModal } from 'components/ui/Modal';
import { ContactForm } from 'components/contact';
import { PrimaryButton } from 'components/ui';
import { buttons } from 'data/general.json';

import { CloseIcon } from './ContactFormModal.style';

interface Props {
    eventName: string;
    ctaButtonLabel?: string;
}

export function ContactFormModal({ eventName, ctaButtonLabel }: Props) {
    const [contactFormOpen, setContactFormOpen] = useState(false);

    // TODO: keep Schedule logic here to be enabled later with A-B testing
    // const { showHandler: showCalendlyPopup } = useCalendlyPopup();

    return (
        <>
            <PrimaryButton
                maxWidth={'100%'}
                onClick={() => setContactFormOpen((visible) => !visible)}
                // onClick={showCalendlyPopup}
                name={eventName}
            >
                {ctaButtonLabel || buttons.contactCTA}
            </PrimaryButton>
            <BaseModal
                fullScreen={false}
                open={contactFormOpen}
                onClose={() => setContactFormOpen(false)}
            >
                <>
                    <CloseIcon onClick={() => setContactFormOpen(false)} />
                    <ContactForm submitButtonPrefix={'why-work-with-us-'} />
                </>
            </BaseModal>
        </>
    );
}
