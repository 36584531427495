import React from 'react';

import { buildProject, ctaButtonLabel } from 'data/solutii-automotive.json';
import { solutionsAutomotiveContactFormBottom } from 'data/analytics.json';

import { ButtonContainer, Container, Content, Title, Description } from './BuildYourProduct.style';
import { ContactFormModal } from '../ContactFormModal';

export function BuildYourProduct() {
    return (
        <Container>
            <Content>
                <Title>{buildProject.title}</Title>
                <Description>{buildProject.description}</Description>
                <ButtonContainer>
                    <ContactFormModal
                        eventName={solutionsAutomotiveContactFormBottom}
                        ctaButtonLabel={ctaButtonLabel}
                    />
                </ButtonContainer>
            </Content>
        </Container>
    );
}
