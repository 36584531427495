import React from 'react';

import { award } from 'data/solutii-automotive.json';
import {
    BackgroundContainer,
    Title,
    Details,
    Description,
    Image,
    FlexContainer
} from './Award.style';
import { useQueryContext } from 'hooks';

export function Award() {
    const {
        images: {
            whyWorkWithUs: {
                award: { image }
            }
        }
    } = useQueryContext();

    return (
        <BackgroundContainer>
            <FlexContainer>
                <Image
                    alt={award.image.alt}
                    mobile={image.mobile}
                    tablet={image.tablet}
                    desktop={image.desktop}
                    position="relative"
                    zIndex={0}
                />
                <Details>
                    <Title>{award.title}</Title>
                    <Description>{award.description}</Description>
                </Details>
            </FlexContainer>
        </BackgroundContainer>
    );
}
