import styled, { css } from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    DESKTOP_GRID_SIZE,
    TABLET_GRID_SIZE,
    PHONE_GRID_SIZE
} from 'consts';

export const SectionContainer = styled.section`
    display: flex;
    justify-content: center;
`;

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        max-width: ${PHONE_GRID_SIZE}rem;
    }
`;

export const Title = styled.h1`
    color: ${Colors.violet};
    padding-top: 24rem;
    max-width: 78rem;
    text-align: center;

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 1.4;
        padding-top: 8rem;
    }
`;

export const Subtitle = styled.p`
    color: ${Colors.violet};
    margin-top: 4rem;
    margin-bottom: 12rem;
    @media ${MediaQueries.phone} {
        text-align: center;
    }
`;

export const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: ${Colors.violet};
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding-bottom: 8rem;
    }

    @media ${MediaQueries.tablet} {
        padding: 0 7.4rem;
        padding-bottom: 14rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
    }
`;

export const ProcessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        margin-bottom: 13rem;

        :nth-child(2n) {
            flex-direction: row-reverse;
        }
        :nth-child(2n + 1) {
            flex-direction: row;
        }
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 8rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const TextContainer = styled.div<{ align: 'left' | 'right' }>`
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    align-items: center;
    justify-content: center;

    @media ${MediaQueries.phone} {
        padding-bottom: 4rem;
    }

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 6}rem;
        ${({ align }) => {
            if (align === 'left')
                return css`
                    margin-left: 12rem;
                    text-align: start;

                    p {
                        text-align: start;
                    }
                `;
            if (align === 'right')
                return css`
                    margin-right: 12rem;
                    text-align: end;

                    p {
                        text-align: end;
                    }
                `;
        }}
    }
`;

export const Picture = styled.img`
    @media ${MediaQueries.desktop} {
        width: 58rem;
        height: 43.5rem;
    }

    @media ${MediaQueries.tablet} {
        width: 100%;
    }

    @media ${MediaQueries.phone} {
        width: 100%;
    }
`;

export const ItemTitle = styled.h3<{ color: string }>`
    margin: 4rem 0 2rem 0;
    color: ${(props) => props.color};

    @media ${MediaQueries.tablet} {
        text-align: center;
        font-size: 3.7rem;
        font-weight: bold;
        margin: 4rem 0 4rem 0;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        font-size: 2.4rem;
        line-height: 1.6;
        margin: 2rem 0 2rem 0;
        font-weight: 500;
    }
`;

export const Description = styled.p`
    line-height: 1.5;
    text-align: center;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: 48rem;
    }
`;

export const CirclesContainer = styled.div`
    bottom: 4rem;
    left: -92rem;
    position: absolute;
    z-index: -1;
`;
