import React from 'react';

import { endToEndServices } from 'data/solutii-automotive.json';
import { MediaQueries, Icons } from 'environment';
import { useQueryContext, useMediaQuery } from 'hooks';

import {
    Container,
    Content,
    Description,
    Details,
    Image,
    Title,
    CirclesContainer
} from './EndToEndServices.style';

export function EndToEndServices() {
    const {
        images: {
            whyWorkWithUs: {
                endToEnd: { image }
            }
        }
    } = useQueryContext();
    const isTablet = useMediaQuery(MediaQueries.tablet);
    const isDesktop = useMediaQuery(MediaQueries.desktop);

    return (
        <Container>
            <Image
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={endToEndServices.alt}
            >
                <CirclesContainer>
                    {isDesktop && <Icons.CirclesRight2 />}
                    {isTablet && <Icons.CirclesRightTablet2 />}
                </CirclesContainer>
                <Content>
                    <Details>
                        <Title>{endToEndServices.title}</Title>
                        <Description>{endToEndServices.description}</Description>
                    </Details>
                </Content>
            </Image>
        </Container>
    );
}
