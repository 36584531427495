import React from 'react';

import { ourProcess } from 'data/solutii-automotive.json';
import { MediaQueries, Icons, Colors } from 'environment';
import { useMediaQuery } from 'hooks';

import {
    Container,
    ProcessContainer,
    TextContainer,
    Title,
    Subtitle,
    ItemTitle,
    Description,
    Picture,
    ListContainer,
    CirclesContainer,
    SectionContainer
} from './OurProcess.style';

export function OurProcess() {
    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const colors = [
        Colors.green,
        Colors.magenta,
        Colors.orange,
        Colors.violet,
        Colors.green,
        Colors.magenta
    ];

    return (
        <SectionContainer>
            <Container>
                <Title>{ourProcess.title}</Title>
                <Subtitle>{ourProcess.description}</Subtitle>
                <ListContainer>
                    {ourProcess.items.map((item, index) => {
                        return (
                            <ProcessContainer key={`our-process-${item.title}-${index}`}>
                                <Picture src={`/images/${item.image.src}`} alt={item.image.alt} />
                                <TextContainer align={index % 2 === 0 ? 'left' : 'right'}>
                                    <ItemTitle color={colors[index]}>{item.title}</ItemTitle>
                                    <Description>{item.description}</Description>
                                </TextContainer>
                            </ProcessContainer>
                        );
                    })}
                </ListContainer>
                {isDesktop && (
                    <CirclesContainer>
                        <Icons.CirclesLeft2 />
                    </CirclesContainer>
                )}
            </Container>
        </SectionContainer>
    );
}
