import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';

export const BackgroundContainer = styled.section`
    background: ${Colors.green};
    padding: 3rem;
    display: flex;
    justify-content: center;
`;

export const FlexContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${MediaQueries.desktop} {
        width: 150rem;
        transform: translateX(-18rem);
    }

    @media ${MediaQueries.tablet} {
        padding-top: 3rem;
        padding-bottom: 9rem;
        flex-direction: column;
    }

    @media ${MediaQueries.phone} {
        padding-top: 3rem;
        padding-bottom: 3rem;
        flex-direction: column;
    }
`;

export const Image = styled(BackgroundImage)`
    @media ${MediaQueries.desktop} {
        max-width: 78rem;
        padding-left: 2rem;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        padding-left: 4rem;
        max-width: ${DESKTOP_GRID_COLUMN * 7 + DESKTOP_GRID_SPACING * 6}rem;
    }

    @media ${MediaQueries.tablet} {
        padding-top: 8rem;
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        padding-top: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

export const Title = styled.h2`
    text-align: center;
    margin-bottom: 4rem;

    @media ${MediaQueries.desktop} {
        text-align: left;
    }

    @media ${MediaQueries.phone} {
        font-size: 3.6rem;
        line-height: 5rem;
        font-weight: 700;
    }
`;

export const Description = styled.p`
    text-align: center;

    @media ${MediaQueries.desktop} {
        text-align: left;
    }
`;
