import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: ${Colors.violet};

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SIZE * 7}rem;
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        margin: 12rem 1rem;
    }

    @media ${MediaQueries.phone} {
        padding: 6rem 2rem;
    }
`;

export const Title = styled.h3`
    text-align: center;

    @media ${MediaQueries.desktop} {
        margin-top: 12rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        font-size: 2.4rem;
        line-height: 1.58;
        margin-bottom: 2rem;
        font-weight: 500;
    }
`;
export const Description = styled.p`
    text-align: center;
    max-width: 78rem;

    @media ${MediaQueries.phone} {
        text-align: center;
        padding-bottom: 4rem;
    }

    @media ${MediaQueries.tablet} {
        padding-bottom: 8rem;
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        margin-top: 8rem;
    }
`;
